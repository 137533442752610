import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '../../../utils';

import { Link } from '../../atoms';

const SubMenu = styled.ul`
  list-style: none;
  padding: 0 0 14px;
  margin: 30px 0;
  font-size: 20px;
  border-bottom: 1px solid ${themeGet('colors.grayscale.400')};
`;

const StyledSidebar = styled.aside`
  flex-shrink: 0;
  width: 140px;
  min-height: calc(100vh - 80px);
  padding-right: ${themeGet('space.xxl')}px;
  border-right: 1px solid ${themeGet('colors.grayscale.400')};

  @media (min-width: 1400px) {
    width: 240px;
  }
`;

const Nav = styled.nav`
  position: sticky;
  top: ${themeGet('space.xl')}px;
  ${space};
`;

const NavLinkItem = styled(Link)`
  border-left: 4px solid transparent;

  &.active {
    color: ${themeGet('colors.black')};
    border-color: ${themeGet('colors.brand')};
  }
`;

export { SubMenu, StyledSidebar, Nav, NavLinkItem };
