import React from 'react';

import Inner from '../../components/templates/Inner';
import { Box, Flex, Text, Title, Divider } from '../../components/atoms';
import { themeGet } from '../../utils';

import { principles as nav } from '../../nav';

const Typography = () => (
  <Inner nav={nav} title="Typography">
    <Title size="h4" mb="m">
      Typography
    </Title>
    <Divider mb="l" />
    <Flex mb="l">
      <Box>
        <Title as="h3" size="h5" m={0}>
          Behind Content
        </Title>
        <Text fontSize="m" m={0}>
          Main font
        </Text>
      </Box>
    </Flex>
    <Divider mb="xxl" />
    <Title as="h1" size="h1" mb={10}>
      Heading H1
    </Title>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.heading.h1')}
      pt
    </Text>
    <Title m={0}>Heading H2</Title>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.heading.h2')}
      pt
    </Text>
    <Title as="h3" size="h3" m={0}>
      Heading H3
    </Title>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.heading.h3')}
      pt
    </Text>
    <Title as="h4" size="h4" m={0}>
      Heading H4
    </Title>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.heading.h4')}
      pt
    </Text>
    <Title as="h5" size="h5" m={0}>
      Heading H5
    </Title>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.heading.h5')}
      pt
    </Text>
    <Text m={0} fontSize="l">
      Content
    </Text>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.l')}
      pt
    </Text>
    <Text fontSize="m" m={0}>
      Content
    </Text>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.m')}
      pt
    </Text>
    <Text fontSize="s" m={0}>
      Small text
    </Text>
    <Text color="grayscale.600" fontSize="m">
      {' '}
      {themeGet('fontSizes.s')}
      pt
    </Text>
  </Inner>
);

export default Typography;
