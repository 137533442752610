import React from 'react';
import t from 'prop-types';
import { SubMenu, StyledSidebar, Nav, NavLinkItem } from './styles';
import logo from '../../../assets/img/logo.svg';

import { Box, Link } from '../../atoms';

const Sidebar = ({ nav }) => {
  return (
    <StyledSidebar>
      <Link href="https://heartbeat.ua" mb="xxl" display="block" width={50} height={80}>
        <img src={logo} alt="" />
      </Link>
      {nav && (
        <Nav ml="xs">
          <SubMenu>
            {nav.map(({ name, path }) => {
              return (
                <Box as="li" mb="14px" key={name}>
                  <NavLinkItem to={path} pl="20px" activeClassName="active" color="grayscale.800">
                    {name}
                  </NavLinkItem>
                </Box>
              );
            })}
          </SubMenu>
        </Nav>
      )}
    </StyledSidebar>
  );
};

Sidebar.propTypes = {
  nav: t.array,
};

export default Sidebar;
