const list = [
  {
    name: '.pulse',
    path: '/',
  },
  {
    name: 'Introduction',
    path: '/introduction',
  },
  {
    name: 'Core values',
    path: '/core-values',
  },
  {
    name: 'Principles',
    path: '/principles',
  },
  {
    name: 'Design tips',
    path: '/design-tips',
  },
];

export default list;
