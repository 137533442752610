const dt = 'design-tips';
const pri = 'principles';
const comp = 'components';

const designTips = [
  {
    path: `/${dt}/accessibility`,
    name: 'Accessibility',
  },
  {
    path: `/${dt}/colors`,
    name: 'Colors',
  },
  {
    path: `/${dt}/components`,
    name: 'Components',
  },
  {
    path: `/${dt}/icons`,
    name: 'Icons',
  },
  {
    path: `/${dt}/layout`,
    name: 'Layout',
  },
  {
    path: `/${dt}/less-is-more`,
    name: 'Less is more',
  },
  {
    path: `/${dt}/typography`,
    name: 'Typography',
  },
];

const principles = [
  {
    path: `/${pri}/colors`,
    name: 'Colors',
  },
  {
    path: `/${pri}/typography`,
    name: 'Typography',
  },
];

const components = [
  {
    path: `/${comp}/box`,
    name: 'Box',
  },
  {
    path: `/${comp}/flex`,
    name: 'Flex',
  },
  {
    path: `/${comp}/container`,
    name: 'Container',
  },
  {
    path: `/${comp}/row`,
    name: 'Row',
  },
  {
    path: `/${comp}/col`,
    name: 'Col',
  },
  {
    path: `/${comp}/button`,
    name: 'Button',
  },
  {
    path: `/${comp}/divider`,
    name: 'Divider',
  },
  {
    path: `/${comp}/title`,
    name: 'Title',
  },
  {
    path: `/${comp}/text`,
    name: 'Text',
  },
  {
    path: `/${comp}/inputField`,
    name: 'Input Field',
  },
  {
    path: `/${comp}/textarea`,
    name: 'Textarea',
  },
];

export { designTips, principles, components };
