import React from 'react';
import styled from 'styled-components';
import { themeGet } from '../../../utils';

import { Flex, Link } from '../../atoms';

import navList from './list';

const StyledLink = styled(Link)`
  border-bottom: 3px solid transparent;

  &.active {
    color: ${themeGet('colors.black')};
    border-bottom-color: ${themeGet('colors.brand')};
  }
`;

const TopNav = () => (
  <Flex mb="xxl">
    {navList.map(({ name, path }) => {
      return (
        <StyledLink
          key={name}
          mr="l"
          to={path}
          color="grayscale.800"
          activeClassName="active"
          partiallyActive={path !== '/'}
        >
          {name}
        </StyledLink>
      );
    })}
  </Flex>
);

export default TopNav;
