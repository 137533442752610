import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { useLocation } from '@reach/router';
import { themeGet } from '../../../utils';
import theme from '../../../theme';

import { Box, Title, Text } from '../../atoms';
import Sidebar from '../../molecules/Sidebar';
import TopNav from '../../molecules/TopNav';
import Heartbeat from '../../molecules/Heartbeat';

import Head from '../../helpers/Head';

import '../../../assets/css/normalize.css';
import '../../../assets/css/base.css';

const Wrapper = styled.div`
  display: flex;
  padding-top: ${themeGet('space.xl')}px;
  padding-left: ${themeGet('space.xl')}px;
  padding-bottom: ${themeGet('space.xl')}px;
  font-family: ${themeGet('fonts.primary')}, sans-serif;
  color: ${themeGet('colors.black')};
  font-size: ${themeGet('fontSizes.l')}px;
  line-height: ${themeGet('lineHeights.l')};
`;

const Content = styled(Box)`
  flex: 1;
  padding: 0 80px ${themeGet('space.xxl')}px;
  max-width: 100%;
  overflow: hidden;
`;

const Blockquote = styled(Box)`
  border-left: 3px solid ${themeGet('colors.blockquote')};
  padding: 20px 0 1px 30px;
  margin: 0;
  max-width: 560px;
`;

const components = {
  h1: (props) => <Title size="h1" as="h1" {...props} />,
  h2: (props) => <Title {...props} />,
  h3: (props) => <Title size="h3" as="h3" {...props} />,
  h4: (props) => <Title size="h4" as="h4" {...props} />,
  h5: (props) => <Title size="h5" as="h5" {...props} />,
  p: (props) => <Text {...props} />,
  blockquote: (props) => <Blockquote {...props} as="blockquote" />,
};

const Inner = ({ children, nav, title }) => {
  const { pathname } = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <Head title={title} slug={pathname} />
      <Wrapper>
        <Sidebar nav={nav} />
        <Content mt="s">
          <TopNav />
          <Box maxWidth={1072}>
            <MDXProvider components={components}>{children}</MDXProvider>
            <Heartbeat />
          </Box>
        </Content>
      </Wrapper>
    </ThemeProvider>
  );
};

Inner.propTypes = {
  children: PropTypes.node,
  nav: PropTypes.array,
  title: PropTypes.string,
};

export default Inner;
